import React from "react"
import Layout from '../components/layout'
import Paragraph from '../components/paragraph/Paragraph'
import BannerImage from '../components/image/BannerImage'
import AddtlBtns from '../components/additionalButtons/AddtlButtonsComponent'
import SEO  from '../components/SEO'
import ContactForm from '../components/contact/ContactForm'
import ContactHours from "../components/contact/ContactHours"
import BottomMap from "../components/contact/BottomMap"
import HTML from "../components/HTML"


function Contact(props) {
  const { pageContext } = props
  const { pageContent, theme,dealerInfo,inventorymanagement,lead_url,site_id } = pageContext
  const dealerHours = dealerInfo
  const dealerLoc = dealerInfo
  const document = typeof window !== 'undefined' && window.document

  return (
    <Layout>
        <HTML key={"HTML"} data={pageContent.HTML} />
        <BannerImage data={pageContent.BannerImage}></BannerImage>
        <Paragraph data={pageContent.Paragraph}></Paragraph>
        <ContactHours data={dealerHours}/>
        <ContactForm data={pageContent.ContactForm} dealer={dealerInfo} site_id={pageContext.site_id} 
          lead_url={pageContext.lead_url} car={null} pageSource='Contact' pageURL={document.URL}></ContactForm>
        <BottomMap data={dealerLoc} />
        <AddtlBtns disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} >
        </AddtlBtns>
        <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />
    </Layout>
  )
}

export default Contact
